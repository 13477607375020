.App {
  text-align: center;
}

.App-logo {
  height: 6rem;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  color: white;
}

span {
  color: #f359f8;
}